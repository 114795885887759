import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import "../../assets/style/Notes.css";
import { fetcher } from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import useSWR, { mutate } from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import FileUploader from "../../components/Commons/FileUploader";
import { useParams } from "react-router-dom";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

interface AddEditAdCategoryProps {}

const AddEditAdCategory: React.FC<AddEditAdCategoryProps> = ({}) => {
  const [banner, setBanner] = useState(null);
  const [adData, setAdData] = useState({});
  const [openError, setOpenError] = useState(false);

  let { id } = useParams();
  const navigate = useNavigate();

  // For POST Request to add a new Ad Category
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/ads`, `post`, adData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  //for GET Request to get ad details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/ads/${id}` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;
    setBanner(getData?.result?.banner);
  }, [getData]);

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/ads/${id}`, `put`, adData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          text: getData?.result?.text ?? "",
          navTo: getData?.result?.navTo ?? "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Ad Name is required"),
        })}
        onSubmit={(values, actions) => {
          const payload = { ...values, banner };
          setAdData(payload);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit Ad Category:" : "Create a new Ad Category:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Ad Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <TextField
                error={Boolean(touched.text && errors.text)}
                helperText={touched.text && errors.text}
                label="Ad text"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="text"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.text}
              />
              <TextField
                error={Boolean(touched.navTo && errors.navTo)}
                helperText={touched.navTo && errors.navTo}
                label="URL to navigate to on clicking the banner"
                margin="normal"
                name="navTo"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.navTo}
              />

              <Box style={{ marginTop: "30px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Ad banner:</p>
                {Boolean(id) && Boolean(getData?.result?.banner) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.banner}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <FileUploader setFileUrl={(url) => setBanner(url)} />
              </Box>
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Ad Category" : "Create Ad Category"}
              </Button>
              <Link to="/adCategory">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditAdCategory;
