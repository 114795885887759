import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

interface NotesLibraryProps {}

const NotesLibrary: React.FC<NotesLibraryProps> = ({}) => {
  const { data, error } = useSWR(`/notesLibrary`, fetcher, staticOptions);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result.forEach((item) => {
      item.isVisible = item.isVisible.toString();
      item.isActive = item.isActive.toString();
      item.notes?.length > 0 &&
        Array.isArray(item.notes) &&
        (item.notes = item?.notes?.join(", "));
    });
  }, [data]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Link to="/notelibrary/addNoteLibrary">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "180px" }}
        >
          <LibraryBooksIcon />
          Add Notes Library
        </div>
      </Link>
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          uniqueId={"id"}
          enableEdit={true}
          enableDelete={true}
          navTo={"notelibrary/editNoteLibrary"}
          apiEndpoint={"notesLibrary"}
          dataOrder={[
            "name",
            "id",
            "type",
            "category",
            "isVisible",
            "isActive",
          ]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Notes Libraries.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default NotesLibrary;
