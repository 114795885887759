import React from "react";

interface TimeFieldProps {
  date: string;
  showTime: boolean;
}

const TimeField: React.FC<TimeFieldProps> = ({ date, showTime }) => {
  if (!!!date) {
    return null;
  }

  let dateTime = new Date(Date.parse(date));

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  let dateString =
    days[dateTime.getDay()] +
    ", " +
    dateTime.getDate() +
    " " +
    months[dateTime.getMonth()] +
    "'" +
    dateTime.getFullYear().toString().substr(2);
  let timeString = dateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <div style={{ width: "fit-content" }}>
      <p style={{ margin: "2px", marginTop: "7px" }}>{dateString}</p>
      {showTime ? (
        <p style={{ margin: "2px", marginBottom: "7px", color: "#999" }}>
          {timeString.toLowerCase()}
        </p>
      ) : null}
    </div>
  );
};

export default TimeField;
