import React from "react";
import { Typography, Breadcrumbs, Link, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { menuItems } from "../../utils/DashboardItems";

const useStyles = makeStyles((theme) => ({
  linkItem: {
    fontFamily: "Archia",
    fontWeight: 500,
    color: "#2f4f4f",
    cursor: "pointer",
  },
  typograhyItem: {
    fontFamily: "Archia",
    fontWeight: 600,
    color: "#3949ab",
    fontSize: "1.25rem",
    letterSpacing: "unset",
  },
}));

interface BreadcrumbProps {
  currentRoutePaths: string[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ currentRoutePaths }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const getCrumbs = () => {
    return currentRoutePaths.map((path, index) => {
      const text = menuItems.hasOwnProperty(path)
        ? menuItems[path].displayName
        : path;
      return index !== currentRoutePaths.length - 1 ? (
        <Link
          key={index}
          className={classes.linkItem}
          onClick={() => {
            navigate(path);
          }}
        >
          {text}
        </Link>
      ) : (
        <Typography key={index} className={classes.typograhyItem}>
          {text}
        </Typography>
      );
    });
  };

  return <Breadcrumbs>{getCrumbs()}</Breadcrumbs>;
};

export default Breadcrumb;
