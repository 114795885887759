import React, { useEffect, useState } from "react";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../../assets/style/Notes.css";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import QuillTextEditor from "../../components/Commons/QuillTextEditor";

interface AddEditSubscriptionProps {}

const animatedComponents = makeAnimated();

const AddEditSubscription: React.FC<AddEditSubscriptionProps> = ({}) => {
  const [notesLibrary, setNotesLibrary] = useState([]);
  const [mainsTS, setMainsTS] = useState([]);
  const [selectedNLOption, setSelectedNLOption] = useState(null);
  const [selectedTSOption, setSelectedTSOption] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [openError, setOpenError] = useState(false);
  const [selectedNotesLibrary, setSelectedNotesLibrary] = useState(null);
  const [selectedMainsTS, setSelectedMainsTS] = useState(null);
  const [details, setDetails] = useState<string>("");
  const [subscribedDetails, setSubscribedDetails] = useState<string>("");
  const [selectedDates, setSelectedDates] = useState<{
    startDate: Date | string;
    endDate: Date | string;
  }>({ startDate: null, endDate: null });

  let { id } = useParams();
  const navigate = useNavigate();

  //For GET Request to get all the notes libraries
  const { data: notesLibraryData, error: notesLibraryError } = useSWR(
    `/notesLibrary`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(notesLibraryData) || Boolean(notesLibraryData?.error)) return;
    const dropdownData = [];
    notesLibraryData?.result?.forEach((item) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setNotesLibrary(dropdownData);
  }, [notesLibraryData]);

  useEffect(() => {
    if (!Boolean(notesLibraryError) || !Boolean(notesLibraryError?.status))
      return;
    console.log(notesLibraryError.info);
    setOpenError(true);
  }, [notesLibraryError]);

  //For GET Request to get all the Mains Test Series
  const { data: mainsTSData, error: mainsTSError } = useSWR(
    `/mainsTS`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(mainsTSData) || Boolean(mainsTSData?.error)) return;
    const dropdownData = [];
    mainsTSData?.result?.mainsTSList?.forEach((item) => {
      let option = { value: item._id, label: item.name };
      dropdownData.push(option);
    });
    setMainsTS(dropdownData);
  }, [mainsTSData]);

  useEffect(() => {
    if (!Boolean(mainsTSError) || !Boolean(mainsTSError?.status)) return;
    console.log(mainsTSError.info);
    setOpenError(true);
  }, [mainsTSError]);

  // For POST Request
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/subscriptionType`, `post`, subscriptionData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  //for GET Request to get notes details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id)
      ? `/subscriptionType/${id}?notesLibrary=true&mainsTestSeries=true`
      : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;
    setSelectedDates({
      startDate: Boolean(getData?.result.startDate)
        ? new Date(getData?.result.startDate)
        : null,
      endDate: Boolean(getData?.result.endDate)
        ? new Date(getData?.result.endDate)
        : null,
    });
    let modifiedNotesLibrary = [],
      modifiedTestSeries = [];
    getData?.result?.notesLibrary?.forEach((item) => {
      const notesLibrary = { value: item._id, label: item.name };
      modifiedNotesLibrary.push(notesLibrary);
    });
    if (modifiedNotesLibrary.length) {
      setSelectedNLOption(modifiedNotesLibrary);
      setSelectedNotesLibrary(modifiedNotesLibrary);
    }
    getData?.result?.testSeries?.forEach((item) => {
      const testSeries = { value: item._id, label: item.name };
      modifiedTestSeries.push(testSeries);
    });
    if (modifiedTestSeries.length) {
      setSelectedMainsTS(modifiedTestSeries);
      setSelectedTSOption(modifiedTestSeries);
    }
  }, [getData]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/subscriptionType/${id}`, `put`, subscriptionData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const setTextEditorData = (data: string) => {
    setDetails(data);
  };

  const setSubscribedTextEditorData = (data: string) => {
    setSubscribedDetails(data);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          description: getData?.result?.description ?? "",
          // category: getData?.result?.category ?? "",
          type: getData?.result?.type ?? "",
          section: getData?.result?.section ?? "",
          category: getData?.result?.category ?? "",
          isVisible: getData?.result?.isVisible ?? false,
          isActive: getData?.result?.isActive ?? false,
          isMentorshipEnabled: getData?.result?.isMentorshipEnabled ?? false,
          price: getData?.result?.price ?? 0,
          strikePrice: getData?.result?.strikePrice ?? 0,
          notesLibrary: [],
          testSeries: [],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Subscription Name is required"),
          description: Yup.string().required("Description is required"),
          section: Yup.string().required("Section is required"),
        })}
        onSubmit={(values, actions) => {
          let notesLibrary = [],
            testSeries = [];
          selectedNLOption?.forEach((item) => {
            notesLibrary.push(item.value);
          });
          selectedTSOption?.forEach((item) => {
            testSeries.push(item.value);
          });

          setSubscriptionData({
            ...values,
            ...selectedDates,
            notesLibrary,
            testSeries,
            ...(Boolean(details) && { details }),
            ...(Boolean(subscribedDetails) && { subscribedDetails }),
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit Subscription:" : "Create a new Subscription:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Subscription Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <TextField
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                label="Description"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.description}
                required
              />
              {/* <TextField
                label="Category"
                margin="normal"
                name="category"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
                variant="outlined"
              /> */}
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 11 }}>
                {Boolean(selectedNotesLibrary) && (
                  <MultiSelect
                    options={notesLibrary}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Notes Libraries"
                    components={animatedComponents}
                    onChange={setSelectedNLOption}
                    defaultValue={selectedNotesLibrary}
                  />
                )}
                {!Boolean(selectedNotesLibrary) && (
                  <MultiSelect
                    options={notesLibrary}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Notes Libraries"
                    components={animatedComponents}
                    onChange={setSelectedNLOption}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 10 }}>
                {Boolean(selectedMainsTS) && (
                  <MultiSelect
                    options={mainsTS}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Test Series"
                    components={animatedComponents}
                    onChange={setSelectedTSOption}
                    defaultValue={selectedMainsTS}
                  />
                )}
                {!Boolean(selectedMainsTS) && (
                  <MultiSelect
                    options={mainsTS}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Test Series"
                    components={animatedComponents}
                    onChange={setSelectedTSOption}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "30px" }}>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      className="dropdown-form"
                      required
                    >
                      <InputLabel>Section</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Section"
                        name="section"
                        value={values.section}
                      >
                        <MenuItem value="notes">Notes</MenuItem>
                        <MenuItem value="tests">Tests</MenuItem>
                        <MenuItem value="combos">Combos</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Type</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Type"
                        name="type"
                        value={values.type}
                      >
                        <MenuItem value="10th">10th</MenuItem>
                        <MenuItem value="12th">12th</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className="dropdown-form">
                      <InputLabel>Category</InputLabel>
                      <Select
                        onChange={handleChange}
                        label="Category"
                        name="category"
                        value={values.category}
                      >
                        <MenuItem value={"written"}>Written</MenuItem>
                        <MenuItem value={"practical"}>Practical</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ marginTop: "40px" }}>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="Price"
                        margin="normal"
                        name="price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.price}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="Strike Price"
                        margin="normal"
                        name="strikePrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.strikePrice}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ marginTop: "30px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        disablePast={false}
                        variant="inline"
                        inputVariant="outlined"
                        label="Start Date"
                        format="dd/MM/yyyy"
                        value={selectedDates.startDate}
                        onChange={(date) =>
                          setSelectedDates({
                            ...selectedDates,
                            startDate: date?.toJSON() ?? null,
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "0.75rem",
                          opacity: "50%",
                          marginTop: "5px",
                        }}
                      >
                        *Default start date is today
                      </p>
                    </Grid>
                    <Grid item>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        disablePast={true}
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date"
                        format="dd/MM/yyyy"
                        value={selectedDates.endDate}
                        onChange={(date) =>
                          setSelectedDates({
                            ...selectedDates,
                            endDate: date?.toJSON() ?? null,
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "0.75rem",
                          opacity: "50%",
                          marginTop: "5px",
                        }}
                      >
                        *Default end date is 100 years from today
                      </p>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isVisible}
                          onChange={handleChange}
                          name="isVisible"
                        />
                      }
                      label="Is Visible"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                        />
                      }
                      label="Is Active"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isMentorshipEnabled}
                          onChange={handleChange}
                          name="isMentorshipEnabled"
                        />
                      }
                      label="Is Mentorship Enabled"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>Subscription Details:</h4>
                <QuillTextEditor
                  setHtmlData={setTextEditorData}
                  htmlData={getData?.result?.details}
                />
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>
                  Subscribed Details:{" "}
                  <span style={{ fontSize: "14px" }}>
                    (after user has subscribed)
                  </span>
                </h4>
                <QuillTextEditor
                  setHtmlData={setSubscribedTextEditorData}
                  htmlData={getData?.result?.subscribedDetails}
                />
              </Box>
            </div>
            <Box style={{ marginTop: "100px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Subscription" : "Create Subscription"}
              </Button>
              <Link to="/subscriptions">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.!
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditSubscription;
