import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import { FilePlus } from "react-feather";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import TimeField from "../../components/Commons/TimeField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

interface TestSeriesViewProps {}

const TestSeries: React.FC<TestSeriesViewProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const { data, error } = useSWR(`mainsTS/`, fetcher, staticOptions);
  const mainsTSQuestionsList = data?.result?.mainsTSList;

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result?.mainsTSList.forEach((item) => {
      item.topicwiseDownload =
        Boolean(item.topicwiseDownload) && getLinkIcon(item.topicwiseDownload);
      item.sourcewiseDownload =
        Boolean(item.sourcewiseDownload) &&
        getLinkIcon(item.sourcewiseDownload);
      item.createdAt = timeField(item.createdAt);
    });
  }, [data]);

  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  function getLinkIcon(url: string) {
    return (
      <Tooltip title="Open File" arrow>
        <IconButton aria-label="open">
          <LaunchIcon
            color="action"
            onClick={() => window.open(`${AWS_URL}${url}`, "_blank")}
          />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div>
      <Link to="AddTestSeries">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
        >
          <FilePlus size={"20px"} />
          Add TestSeries
        </div>
      </Link>
      {!!!mainsTSQuestionsList ? (
        <LinearLoading />
      ) : (
        <Table
          data={mainsTSQuestionsList}
          uniqueId={"_id"}
          enableEdit={true}
          enableDelete={true}
          navTo={"mainsTestSeries/editTestSeries"}
          apiEndpoint={"mainsTS"}
          dataOrder={["name", "createdAt"]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Notes.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default TestSeries;
