import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import LayoutHeader from "./LayoutHeader";

export const filterContext = React.createContext({
  dateValue: null,
  dataCenterValue: null,
});

const MainLayout = () => {
  const [isShelfOpen, setShelfOpen] = useState<boolean>(true);
  const useStyles = makeStyles<any>((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      display: "flex",
      height: "100%",
      overflow: "hidden",
      width: "100%",
    },
    wrapper: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
      paddingTop: 64,
      [theme.breakpoints.up("lg")]: {
        paddingLeft: isShelfOpen ? 256 : 0,
      },
    },
    contentContainer: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
    },
    content: {
      flex: "1 1 auto",
      height: "100%",
      overflow: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  }));
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        onHamClick={() => setShelfOpen(!isShelfOpen)}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        openShelf={isShelfOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <LayoutHeader />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
