export const staticOptions = {
  revalidateOnMount: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
  shouldRetryOnError: true,
  errorRetryInterval: 5000,
  errorRetryCount: 1,
};

export const AWS_URL =
  "https://sunyaias-resources.s3.ap-south-1.amazonaws.com/content/";

export const WEB_URL = "https://www.boardprepp.com/";
// export const WEB_URL = "http://localhost:3000/";

export const API_URL = "https://api.boardprepp.com/api/";
// export const API_URL = "http://localhost:8080/api/";

export interface objectFormat {
  key: string;
  value: string;
}

export const dataCenters: Array<objectFormat> = [
  { key: "ap10", value: "AP10" },
  { key: "ap11", value: "AP11" },
  { key: "br10", value: "BR10" },
  { key: "eu10", value: "EU10" },
  { key: "eu20", value: "EU20" },
  { key: "jp10", value: "JP10" },
  { key: "us10", value: "US10" },
  { key: "us20", value: "US20" },
  { key: "us21", value: "US20" },
];
export const dateOptions: Array<objectFormat> = [
  { key: "last24hrs", value: "Last 24 hours" },
  { key: "last7days", value: "Last 7 days" },
  { key: "last30days", value: "Last 30 days" },
  { key: "last3months", value: "Last 3 months" },
  { key: "last6months", value: "Last 6 months" },
  { key: "beginning", value: "From beginning" },
];

export const chartColors = {
  purple: {
    color: "#d048b6",
    colorStopOne: "rgba(208, 72, 182, 0.2)",
    colorStopTwo: "rgba(208, 72, 182, 0.05)",
    colorStopThree: "rgba(119,52,169,0)",
    gridLines: "rgba(225, 78, 202, 0.1)",
    avgLine: "#eaaedf",
  },
  blue: {
    color: "#1f8ef1",
    colorStopOne: "rgba(29,140,248,0.2)",
    colorStopTwo: "rgba(29,140,248,0.05)",
    colorStopThree: "rgba(29,140,248,0)",
    gridLines: "rgba(29,140,248,0.1)",
    avgLine: "#9fcff9",
  },
  orange: {
    color: "#fb6b18",
    colorStopOne: "rgba(252, 127, 54, 0.2)",
    colorStopTwo: "rgba(252, 127, 54, 0.05)",
    colorStopThree: "rgba(252, 127, 54, 0)",
    gridLines: "rgba(252, 127, 54, 0.1)",
    avgLine: "rgba(252, 133, 64, 0.75)",
  },
  green: {
    color: "#00d6b4",
    colorStopOne: "rgba(0, 214, 179, 0.2)",
    colorStopTwo: "rgba(0, 214, 179, 0.05)",
    colorStopThree: "rgba(66,134,121,0)",
    gridLines: "rgba(0, 214, 179, 0.1)",
    avgLine: "rgba(0, 214, 179, 0.75)",
  },
};

export const cronExp = {
  AGGREGATES: "Every month",
  SOFTDELETE: "Every month",
  GOALS: "Every hour",
  INAPP_GOALS: "Every hour",
  HARDDELETE: "Every month",
  ORPHANDELETE: "Every week",
};
