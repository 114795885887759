import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions, WEB_URL } from "../../utils/Constants";
import { PlusSquare } from "react-feather";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

interface AdCategoryProps {}

const AdCategory: React.FC<AdCategoryProps> = ({}) => {
  const { data, error } = useSWR(`/ads`, fetcher, staticOptions);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <React.Fragment>
      <Link to="/adCategory/addAdCategory">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "180px" }}
        >
          <PlusSquare size={"20px"} />
          Add Ad Category
        </div>
      </Link>
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          uniqueId={"_id"}
          enableEdit={true}
          enableDelete={true}
          navTo={"adCategory/editAdCategory"}
          apiEndpoint={"ads"}
          dataOrder={["name"]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Blogs.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AdCategory;
