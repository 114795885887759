import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

interface LinearLoadingProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "50%",
      position: "absolute",
      top: "60%",
      left: "60%",
      transform: "translate(-50%, -50%)",
    },
  })
);

const LinearLoading: React.FC<LinearLoadingProps> = ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
};

export default LinearLoading;
