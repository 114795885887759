import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { API_URL } from "../../utils/Constants";
import Cookie from "js-cookie";
import clsx from "clsx";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

interface QuillTextEditorProps {
  htmlData?: string;
  setHtmlData: any;
}

Quill.register("modules/imageUploader", ImageUploader);

var text = "";

const QuillTextEditor: React.FC<QuillTextEditorProps> = ({
  htmlData = null,
  setHtmlData,
}) => {
  const [value, setValue] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const modules = {
    toolbar: toolbarOptions,
    imageUploader: {
      upload: (file: any) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("fileData", file);
          const options = {
            method: "POST",
            body: formData,
            headers: {
              accessToken: Cookie.get("accessToken"),
              userId: Cookie.get("userId"),
            },
          };
          fetch(`${API_URL}upload`, options)
            .then((response) => response.json())
            .then((data) => {
              resolve(data.result?.url);
            })
            .catch((error) => {
              reject("Upload failed");
              console.error("Error:", error);
            });
        });
      },
    },
  };

  return (
    <React.Fragment>
      <ReactQuill
        value={value}
        theme="snow"
        modules={modules}
        onChange={(content) => {
          text = content;
          if (value === null) setValue(htmlData);
        }}
      />

      <div>
        <div
          onClick={() => {
            setValue(text);
            setHtmlData(text);
            setOpenSuccess(true);
          }}
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "100px", marginTop: "10px" }}
        >
          Save
        </div>
        <div
          onClick={() => {
            setValue(text);
            setHtmlData(text);
          }}
          className={clsx(
            "passiveButton",
            "cancelButton",
            "passiveButtonHover"
          )}
          style={{ width: "100px", marginTop: "10px", marginLeft: "20px" }}
        >
          Preview
        </div>
      </div>
      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>Preview:</h3>
      <div style={{ border: "1px solid #ccc" }}>
        <ReactQuill value={value} theme="bubble" readOnly />
      </div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={10000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Saved successfully. Please click CREATE/ADD to save the data to
          Datababse.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ font: [] }],
  [{ align: [] }],
  [{ color: [] }, { background: [] }],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["image", "link"],
  ["clean"],
];

export default QuillTextEditor;
