import React, { useEffect, useState, CSSProperties } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import "../../assets/style/Notes.css";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import _ from "lodash";
import FileUploader from "../../components/Commons/FileUploader";
import FileUploaderPresigned from "../../components/Commons/FileUploaderPresigned";

interface ManualUploadProps {}

interface optionType {
  value: string;
  label: string;
  mainsTSId: string;
}

interface groupType {
  label: string;
  options: optionType[];
}

interface questionsDataType extends Array<groupType> {}

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles: CSSProperties = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const groupLabel: CSSProperties = {
  color: "#B9275C",
  fontWeight: 600,
};

const animatedComponents = makeAnimated();

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span style={groupLabel}>{data?.label}</span>
    <span style={groupBadgeStyles}>{data?.options?.length}</span>
  </div>
);

interface uploadDataType {
  questionId: string;
  mainsTSId: string;
}

const ManualUpload: React.FC<ManualUploadProps> = ({}) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [questionsData, setQuestionsData] = useState<questionsDataType>(null);
  const [fileUrls, setFileUrls] = useState(null);
  const [uploadData, setUploadData] = useState<uploadDataType>(null);
  const [payload, setPayload] = useState({});

  const { data, error } = useSWR(`mainsTS/questions`, fetcher, staticOptions);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;

    //Group by mainsTSId
    const rawData = _.groupBy(
      data?.result?.mainsTSQuestionsList,
      (ques) => ques.mainsTSId
    );

    let groupedData: questionsDataType = [];

    for (let key in rawData) {
      let group: optionType[] = [];
      rawData[key].forEach((ques) => {
        let option: optionType = {
          value: ques._id,
          label: ques.name,
          mainsTSId: ques.mainsTSId,
        };
        group.push(option);
      });

      let mainsGroup: groupType = {
        label: rawData[key][0]?.mainsTSName,
        options: group,
      };
      groupedData.push(mainsGroup);
    }
    setQuestionsData(groupedData);
  }, [data]);

  const { data: postData, error: postError } = useSWR(
    Boolean(payload) && Boolean(uploadData)
      ? [
          `mainsTS/` +
            uploadData.mainsTSId +
            `/questions/` +
            uploadData.questionId +
            `/answer`,
          "POST",
          payload,
        ]
      : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    setOpenSuccess(true);
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }, [postData]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Looks like this is not an email")
            .required("Email cannot be empty"),
        })}
        onSubmit={(values, actions) => {
          setPayload({
            userid: values.email,
            answerPdfUrl: fileUrls,
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                Manual Answer Submission:
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Customer Email ID"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                variant="outlined"
                required
              />
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 10 }}>
                {Boolean(questionsData) && (
                  <Select
                    options={questionsData}
                    formatGroupLabel={formatGroupLabel}
                    onChange={(e: any) =>
                      setUploadData({
                        questionId: e?.value,
                        mainsTSId: e?.mainsTSId,
                      })
                    }
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    isClearable={true}
                    closeMenuOnSelect={true}
                    placeholder="Select Question"
                    components={animatedComponents}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "40px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Upload Customer Answer:*</p>
                {/* <FileUploader setFileUrl={(url) => setFileUrls(url)} /> */}
                <FileUploaderPresigned setFileUrl={(url) => setFileUrls(url)} />
              </Box>
            </div>

            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                Upload Customer Answer
              </Button>
              <Link to="/customer-subscriptions">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Customer answer uploaded successfully.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default ManualUpload;
