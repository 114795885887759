import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated, getUserRoles, isAuthorized } from "./utils/Functions";
import MainLayout from "./layouts/MainLayout/MainLayout";
import DashboardView from "./views/DashboardView/Dashboard";
import CustomersView from "./views/UsersView/Customers";
import MembersView from "./views/UsersView/Members";
import CustomerSubcriptions from "./views/CustomerSubscriptionsView/CustomerSubcriptions";
import AddEditCustomerSubscription from "./views/CustomerSubscriptionsView/AddEditCustomerSubscription";
import ModulesView from "./views/ModulesView/Modules";
import AddModuleView from "./views/ModulesView/AddEditModule";
import SubscriptionsView from "./views/SubscriptionsView/Subscriptions";
import AddEditSubscriptionView from "./views/SubscriptionsView/AddEditSubscription";
import NotesView from "./views/NotesView/Notes";
import AddEditNotesView from "./views/NotesView/AddEditNotes";
import NotesLibraryView from "./views/NotesLibraryView/NotesLibrary";
import AddEditNotesLibraryView from "./views/NotesLibraryView/AddEditNotesLibrary";
import TestsView from "./views/TestsView/Tests";
import TestSeriesView from "./views/TestSeriesView/TestSeriesView";
import LoginView from "./layouts/LoginLayout/Login.js";
import AnswersView from "./views/AnswersView/AnswersView";
import EvaluatorAnswersView from "./views/AnswersView/EvaluatorAnswersView";
import QuestionsView from "./views/QuestionsView/QuestionsView";
import AddQuestions from "./views/QuestionsView/AddQuestions";
import AddTestSeries from "./views/TestSeriesView/AddTestSeries";
import EvaluatorsInfoView from "./views/EvaluatorsInfoView/EvaluatorsInfoView";
import NoAccess from "./components/Commons/NoAccess";
import AddEditUser from "./views/UsersView/AddEditUser";
import BlogsView from "./views/BlogsView/BlogsView";
import AddEditBlogView from "./views/BlogsView/AddEditBlogView";
import AdCategoryView from "./views/AdCategoryView/AdCategory";
import AddEditAdCategoryView from "./views/AdCategoryView/AddEditAdCategory";
import ManualUpload from "./views/ManualUploadView/ManualUpload";
import Mentorship from "./views/MentorshipView/Mentorship";
import Mentors from "./views/UsersView/Mentors";

interface route {
  id: string;
  path: string;
  element: JSX.Element;
  children?: any;
}

interface routesList extends Array<route> {}

//Add Routes here. Each child route has a view.
export const Routes: routesList = [
  {
    id: "MainLayout",
    path: "/",
    element: isAuthenticated() ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: "/",
        element: getUserRoles.isAdmin ? (
          <Navigate to="/modules" />
        ) : getUserRoles.isEvaluator ? (
          <Navigate to="/answers/evaluator" />
        ) : null,
      },

      {
        path: "customers",
        element: isAuthorized("customers") ? <CustomersView /> : <NoAccess />,
      },
      {
        path: "members",
        element: isAuthorized("members") ? <MembersView /> : <NoAccess />,
      },
      {
        path: "mentors",
        element: isAuthorized("mentors") ? <Mentors /> : <NoAccess />,
      },
      {
        path: "adduser",
        element: isAuthorized("members") ? <AddEditUser /> : <NoAccess />,
      },
      {
        path: "edituser/:id",
        element: isAuthorized("members") ? <AddEditUser /> : <NoAccess />,
      },
      {
        path: "customer-subscriptions",
        element: isAuthorized("customer-subscriptions") ? (
          <CustomerSubcriptions />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "customer-subscriptions/addCustomerSubscription",
        element: isAuthorized("customer-subscriptions") ? (
          <AddEditCustomerSubscription />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "payments",
        element: isAuthorized("payments") ? <DashboardView /> : <NoAccess />,
      },
      {
        path: "modules",
        element: isAuthorized("modules") ? <ModulesView /> : <NoAccess />,
      },
      {
        path: "modules/addModule",
        element: isAuthorized("modules") ? <AddModuleView /> : <NoAccess />,
      },
      {
        path: "modules/editModule/:id",
        element: isAuthorized("modules") ? <AddModuleView /> : <NoAccess />,
      },
      {
        path: "subscriptions",
        element: isAuthorized("subscriptions") ? (
          <SubscriptionsView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "subscriptions/addSubscription",
        element: isAuthorized("subscriptions") ? (
          <AddEditSubscriptionView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "subscriptions/editSubscription/:id",
        element: isAuthorized("subscriptions") ? (
          <AddEditSubscriptionView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "notes",
        element: isAuthorized("notes") ? <NotesView /> : <NoAccess />,
      },
      {
        path: "notes/addNotes",
        element: isAuthorized("notes") ? <AddEditNotesView /> : <NoAccess />,
      },
      {
        path: "notes/editNotes/:id",
        element: isAuthorized("notes") ? <AddEditNotesView /> : <NoAccess />,
      },
      {
        path: "notelibrary",
        element: isAuthorized("notelibrary") ? (
          <NotesLibraryView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "notelibrary/addNoteLibrary",
        element: isAuthorized("notelibrary") ? (
          <AddEditNotesLibraryView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "notelibrary/editNoteLibrary/:id",
        element: isAuthorized("notelibrary") ? (
          <AddEditNotesLibraryView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "tests",
        element: isAuthorized("tests") ? <TestsView /> : <NoAccess />,
      },
      // { path: "testseries", element: <TestSeriesView /> },
      {
        path: "answers",
        element: isAuthorized("answers") ? <AnswersView /> : <NoAccess />,
      },
      {
        path: "mainsTestSeries",
        element: isAuthorized("mainsTestSeries") ? (
          <TestSeriesView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "mainsTestSeries/AddTestSeries",
        element: isAuthorized("mainsTestSeries") ? (
          <AddTestSeries />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "mainsTestSeries/editTestSeries/:id",
        element: isAuthorized("mainsTestSeries") ? (
          <AddTestSeries />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "questions",
        element: isAuthorized("questions") ? <QuestionsView /> : <NoAccess />,
      },
      {
        path: "questions/addQuestions",
        element: isAuthorized("questions") ? <AddQuestions /> : <NoAccess />,
      },
      {
        path: "questions/editQuestions/:id",
        element: isAuthorized("questions") ? <AddQuestions /> : <NoAccess />,
      },
      {
        path: "answers/evaluator",
        element: getUserRoles.isEvaluator ? (
          <EvaluatorAnswersView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "blogs",
        element:
          isAuthorized("blogs") || getUserRoles.isEmployee ? (
            <BlogsView />
          ) : (
            <NoAccess />
          ),
      },
      {
        path: "blogs/addBlog",
        element:
          isAuthorized("blogs") || getUserRoles.isEmployee ? (
            <AddEditBlogView />
          ) : (
            <NoAccess />
          ),
      },
      {
        path: "blogs/editBlog/:id",
        element:
          isAuthorized("blogs") || getUserRoles.isEmployee ? (
            <AddEditBlogView />
          ) : (
            <NoAccess />
          ),
      },
      {
        path: "adCategory",
        element: isAuthorized("adCategory") ? <AdCategoryView /> : <NoAccess />,
      },
      {
        path: "adCategory/addAdCategory",
        element: isAuthorized("adCategory") ? (
          <AddEditAdCategoryView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "adCategory/editAdCategory/:id",
        element: isAuthorized("adCategory") ? (
          <AddEditAdCategoryView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "evaluators",
        element: isAuthorized("evaluators") ? (
          <EvaluatorsInfoView />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "manual-upload",
        element: isAuthorized("manual-upload") ? (
          <ManualUpload />
        ) : (
          <NoAccess />
        ),
      },
      {
        path: "mentorship",
        element: isAuthorized("mentorship") ? <Mentorship /> : <NoAccess />,
      },
    ],
  },
  {
    id: "LoginLayout",
    path: "/login",
    element: isAuthenticated() ? <Navigate to="/modules" /> : <LoginView />,
  },
];
