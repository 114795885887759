import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./components/Theme/GlobalStyles";
import theme from "./components/Theme/index.js";
import { Routes } from "./Routes";
import "./assets/style/Global.css";

function App() {
  const routes = useRoutes(Routes);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routes}
    </ThemeProvider>
  );
}

export default App;
