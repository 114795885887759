import React from "react";
import UnderConstruction from "../../components/Commons/UnderConstruction";

interface TestsProps {}

const Tests: React.FC<TestsProps> = ({}) => {
  return (
    <div>
      <UnderConstruction />
    </div>
  );
};

export default Tests;
