import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import NavItem from "../../components/Commons/NavItem";
import { menuItems } from "../../utils/DashboardItems";

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  listItem: {
    paddingLeft: "unset",
    paddingRight: "unset",
    fontFamily: "unset",
    fontWeight: 600,
    color: "#2f4f4f",
    opacity: "90%",
  },
}));

interface propTypes {
  onMobileClose: () => any;
  openMobile: boolean;
  openShelf: boolean;
}

const NavBar: React.FC<propTypes> = ({
  onMobileClose,
  openMobile,
  openShelf,
}) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, []);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        {menuItems?.map((item) => {
          return (
            <List
              key={item.id}
              subheader={
                <ListSubheader className={classes.listItem}>
                  {item.title}
                </ListSubheader>
              }
            >
              {item.subItems.map((subItem) => (
                <NavItem
                  href={subItem.href}
                  key={subItem.title}
                  title={subItem.title}
                  icon={subItem.icon}
                />
              ))}
            </List>
          );
        })}
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open={openShelf}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
