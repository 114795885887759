import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToApp from "@material-ui/icons/Input";
// @ts-ignore
import logo from "../../assets/Images/BoardPrepp.png";
import "../../assets/style/TopBar.css";
import Tooltip from "@material-ui/core/Tooltip";
import Cookie from "js-cookie";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
}));

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => any;
  onHamClick: () => any;
}

const TopBar: React.FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  onHamClick,
}) => {
  const classes = useStyles();
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0}>
      <Toolbar>
        <Hidden smDown>
          <IconButton color="inherit" onClick={onHamClick}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <div className="header">
            <img className="sunyaLogo" src={logo} alt="sunyaLogo" />
            <h3 className="headerText">BoardPrepp Admin Dashboard</h3>
          </div>
        </RouterLink>
        <Box flexGrow={1} />

        <Tooltip title="Logout" arrow>
          <IconButton
            color="inherit"
            onClick={() => {
              Cookie.remove("accessToken");
              Cookie.remove("userId");
              Cookie.remove("roles");
              window.location.replace("/login");
            }}
          >
            <ExitToApp />
          </IconButton>
        </Tooltip>

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
