import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { Button, ListItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "10px",
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
    fontFamily: "archia",
    fontWeight: 600,
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: 600,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

interface propsType {
  className?: string;
  href: string;
  icon: any;
  title: string;
}

const NavItem: React.FC<propsType> = ({
  className,
  href,
  icon: Icon,
  title,
}) => {
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.item, className)} disableGutters>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="18" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
