import React from "react";
import { filterContext } from "../../layouts/MainLayout/MainLayout";
import UnderConstruction from "../../components/Commons/UnderConstruction";

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = ({}) => {
  return (
    <div>
      <UnderConstruction />
    </div>
  );
};

export default Dashboard;
