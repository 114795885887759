import React from "react";
import image from "../../assets/Images/construction.svg";

interface UnderConstructionProps {}

const UnderConstruction: React.FC<UnderConstructionProps> = ({}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img
        src={image}
        style={{ width: "100%", height: "400px", marginTop: "30px" }}
        alt="underconstruction"
      />
      <h2 style={{ color: "#2f4f4f", marginTop: "20px" }}>
        Page is Under Construction.
      </h2>
    </div>
  );
};

export default UnderConstruction;
