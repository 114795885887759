import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import "../../assets/style/Notes.css";
import { fetcher } from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import useSWR, { mutate } from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import FileUploader from "../../components/Commons/FileUploader";
import { useParams } from "react-router-dom";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Plus as PlusIcon } from "react-feather";
import QuillTextEditor from "../../components/Commons/QuillTextEditor";

interface AddEditBlogViewProps {}

const animatedComponents = makeAnimated();

var formField: { [key: string]: string } = {
  name: "",
};

/*
Fields:
Heading
Description
Author
Published Date
time taken to read
Cover Photo
Create tag
tags: (multi select)
Text editor
Ad: Banner, Any text
*/

const AddEditBlogView: React.FC<AddEditBlogViewProps> = ({}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [publishedDate, setPublishedDate] = useState(null);
  const [preSelectedTags, setPreSelectedTags] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(null);
  const [preSelectedAd, setPreSelectedAd] = useState(null);
  const [ads, setAds] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);
  const [newTag, setNewTag] = useState({});
  const [blogData, setBlogData] = useState({});
  const [blogText, setBlogText] = useState<string>("");

  let { id } = useParams();
  const navigate = useNavigate();

  //For GET Request to get all the tags
  const { data: tagsData, error: tagsError } = useSWR(
    `/tags`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(tagsData) || Boolean(tagsData?.error)) return;
    const preTags = [];
    tagsData?.result?.forEach((tag) => {
      let option = { value: tag._id, label: tag.name };
      preTags.push(option);
    });
    setTags(preTags);
  }, [tagsData]);

  useEffect(() => {
    if (!Boolean(tagsError) || !Boolean(tagsError?.status)) return;
    console.log(tagsError.info);
    setOpenError(true);
  }, [tagsError]);

  //For GET Request to get all the ads
  const { data: adsData, error: adsError } = useSWR(
    `/ads`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(adsData) || Boolean(adsData?.error)) return;
    const adsObject = [];
    adsData?.result?.forEach((ad) => {
      let option = { value: ad._id, label: ad.name };
      adsObject.push(option);
    });
    setAds(adsObject);
  }, [adsData]);

  useEffect(() => {
    if (!Boolean(adsError) || !Boolean(adsError?.status)) return;
    console.log(adsError.info);
    setOpenError(true);
  }, [adsError]);

  // For POST Request to add a new Tag
  const { data: tagPostData, error: tagPostError } = useSWR(
    [`/tags`, `post`, newTag],
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(tagPostData) || Boolean(tagPostData?.error)) return;
    mutate("/tags");
    setOpenSuccess(true);
  }, [tagPostData]);

  useEffect(() => {
    if (!Boolean(tagPostError) || !Boolean(tagPostError?.status)) return;
    console.log(tagPostError.info);
    setOpenError(true);
  }, [tagPostError]);

  // For POST Request to create Blog
  const { data: postData, error: postError } = useSWR(
    !Boolean(id) ? [`/blogs`, `post`, blogData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postData?.error)) return;
    navigate("..", { replace: true });
  }, [postData]);

  useEffect(() => {
    if (!Boolean(postError) || !Boolean(postError?.status)) return;
    console.log(postError.info);
    setOpenError(true);
  }, [postError]);

  //for GET Request to get blog details. Depends on id parameter
  const { data: getData, error: getError } = useSWR(
    Boolean(id) ? `/blogs/${id}` : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(getData) || Boolean(getData?.error)) return;

    setPublishedDate(getData?.result?.publishDate ?? null);
    setCoverPhoto(getData?.result?.coverPhoto ?? null);

    let preTags = [];
    getData?.result?.tags?.forEach((tag) => {
      const option = { value: tag._id, label: tag.name };
      preTags.push(option);
    });
    if (preTags.length) {
      setPreSelectedTags(preTags);
      setSelectedTags(preTags);
    }

    if (getData?.result?.adCategory && getData?.result?.adCategory !== "null") {
      const adData = JSON.parse(getData?.result?.adCategory);
      const preAd = {
        value: adData._id,
        label: adData.name,
      };
      setPreSelectedAd(preAd);
      setSelectedAd(preAd);
    }
  }, [getData]);

  useEffect(() => {
    if (!Boolean(getError) || !Boolean(getError?.status)) return;
    console.log(getError.info);
    setOpenError(true);
  }, [getError]);

  //For PUT Request. Depends on the id param
  const { data: putData, error: putError } = useSWR(
    Boolean(id) ? [`/blogs/${id}`, `put`, blogData] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(putError) || !Boolean(putError?.status)) return;
    console.log(putError.info);
    setOpenError(true);
  }, [putError]);

  useEffect(() => {
    if (!Boolean(putData) || Boolean(putData?.error)) return;
    navigate("../..", { replace: true });
  }, [putData]);

  const handleAdd = () => {
    setNewTag({ name: formField.name });
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formField = {
      name: "",
    };
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const setTextEditorData = (data: string) => {
    setBlogText(data);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          name: getData?.result?.name ?? "",
          description: getData?.result?.description ?? "",
          author: getData?.result?.author ?? "",
          readTime: getData?.result?.readTime ?? "",
          isVisible: getData?.result?.isVisible ?? false,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Blog Name is required"),
        })}
        onSubmit={(values, actions) => {
          let tags = [],
            adCategory = selectedAd?.value;
          selectedTags?.forEach((tag: any) => {
            tags.push(tag?.value);
          });

          const payload = {
            ...values,
            publishDate: publishedDate,
            coverPhoto,
            adCategory,
            tags,
            ...(Boolean(blogText) && { blogText }),
          };
          setBlogData(payload);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                color="textPrimary"
                variant="h4"
                style={{ marginBottom: "20px" }}
              >
                {id ? "Edit Blog:" : "Create a new Blog:"}
              </Typography>
            </Box>
            <div className="form-container">
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Blog Name/Title"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                required
              />
              <p
                style={{
                  marginBottom: "10px",
                  color: "gray",
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                Note: Please do not change the blog title once publised since it
                is used as slug.
              </p>
              <TextField
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                label="Description/Summary"
                multiline
                rows={2}
                rowsMax={4}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                value={values.description}
              />
              <Box style={{ marginTop: "20px", cursor: "pointer", zIndex: 11 }}>
                {Boolean(preSelectedTags) && (
                  <MultiSelect
                    options={tags}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Tags"
                    components={animatedComponents}
                    defaultValue={preSelectedTags}
                    onChange={setSelectedTags}
                  />
                )}
                {!Boolean(preSelectedTags) && (
                  <MultiSelect
                    options={tags}
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Tags"
                    components={animatedComponents}
                    onChange={setSelectedTags}
                  />
                )}
                <div
                  className={clsx("buttonEffectShadow")}
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    float: "left",
                    color: "#2f4f4f",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                  onClick={handleClickOpen}
                >
                  <PlusIcon />
                  <p>Add Tag</p>
                </div>
              </Box>
              <Box style={{ marginTop: "40px", cursor: "pointer", zIndex: 10 }}>
                {Boolean(preSelectedAd) && (
                  <MultiSelect
                    options={ads}
                    closeMenuOnSelect={true}
                    placeholder="Select Ad"
                    components={animatedComponents}
                    defaultValue={preSelectedAd}
                    onChange={setSelectedAd}
                  />
                )}
                {!Boolean(preSelectedAd) && (
                  <MultiSelect
                    options={ads}
                    closeMenuOnSelect={true}
                    placeholder="Select Ad"
                    components={animatedComponents}
                    onChange={setSelectedAd}
                  />
                )}
              </Box>
              <Box style={{ marginTop: "20px" }}>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="Author"
                        margin="normal"
                        name="author"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.author}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="Read Time"
                        margin="normal"
                        name="readTime"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.readTime}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              minutes:
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item style={{ marginTop: "15px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        disablePast={false}
                        variant="inline"
                        inputVariant="outlined"
                        label="Published Date"
                        format="dd/MM/yyyy"
                        value={publishedDate}
                        onChange={(date) => setPublishedDate(date?.toJSON())}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ marginTop: "30px" }} className="uploadBox-form">
                <p style={{ marginRight: "20px" }}>Cover Photo:</p>
                {Boolean(id) && Boolean(getData?.result?.coverPhoto) && (
                  <Tooltip title="Open Uploaded File" arrow>
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon
                        color="secondary"
                        onClick={() =>
                          window.open(
                            `${AWS_URL}${getData?.result?.coverPhoto}`,
                            "_blank"
                          )
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <FileUploader setFileUrl={(url) => setCoverPhoto(url)} />
              </Box>
              <Box>
                <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isVisible}
                          onChange={handleChange}
                          name="isVisible"
                        />
                      }
                      label="Is Visible"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box style={{ marginTop: "50px" }}>
                <h4 style={{ marginBottom: "10px" }}>Blog Text:</h4>
                <QuillTextEditor
                  setHtmlData={setTextEditorData}
                  htmlData={getData?.result?.blogText}
                />
              </Box>
            </div>
            <Box style={{ marginTop: "50px" }}>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                {id ? "Update Blog" : "Create Blog"}
              </Button>
              <Link to="/blogs">
                <Button
                  size="large"
                  variant="contained"
                  className={clsx(
                    "passiveButton",
                    "cancelButton",
                    "passiveButtonHover"
                  )}
                >
                  Cancel
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>

      <Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Tag</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add a new Tag for blogs/articles. On clicking "Add" you should see
              the newly added tag in the dropdown list.
            </DialogContentText>
            <div className="form">
              <TextField
                required
                margin="dense"
                id="name"
                label="Tag Name"
                type="name"
                variant="outlined"
                className="nameField"
                onChange={(e) => {
                  formField.name = e.target.value;
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className={clsx(
                "passiveButton",
                "cancelButton",
                "passiveButtonHover"
              )}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAdd}
              className={clsx("activeButton", "addButton", "activeButtonHover")}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>

      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error. Something went wrong.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Tag added successfully!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default AddEditBlogView;
