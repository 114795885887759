import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import LaunchIcon from "@material-ui/icons/Launch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { green } from "@material-ui/core/colors";

interface Props {
  setFileUrl?: (url: string) => void;
}
const FileUploader: React.FC<Props> = ({ setFileUrl }) => {
  const [fileData, setFileData] = useState({});
  const [uploadStatus, setUploadStatus] = useState<string>("NOT_UPLOADING");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const { data, error } = useSWR(
    [`/upload`, `post`, fileData],
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
    setUploadStatus("NOT_UPLOADING");
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    setUploadStatus("UPLOADED");
    setOpenSuccess(true);
    setFileUrl(data?.result?.id);
  }, [data]);

  function fileUpload(e) {
    if (!e.target?.files[0]) return;
    setUploadStatus("UPLOADING");
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("fileData", file);
    setFileData(formData);
  }

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <>
      {uploadStatus === "UPLOADING" && <CircularProgress size={30} />}
      {uploadStatus === "UPLOADED" && (
        <Tooltip title="Open File" arrow>
          <IconButton aria-label="open">
            <LaunchIcon
              color="primary"
              onClick={() => window.open(data?.result?.url, "_blank")}
              style={{ color: green[700] }}
            />
          </IconButton>
        </Tooltip>
      )}

      <Button
        variant="contained"
        component="label"
        size="small"
        startIcon={<CloudUploadIcon color="action" />}
      >
        Upload File
        <input
          id="inputOptions"
          type="file"
          name="sourcewise"
          accept="application/pdf,image/*"
          style={{ display: "none" }}
          onChange={fileUpload}
        />
      </Button>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          File uploaded successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while uploading file!
        </Alert>
      </Snackbar>
    </>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default FileUploader;
