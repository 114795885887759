import React, { useState, useEffect } from "react";
import { Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";

const useStyles = makeStyles((theme) => ({
  headerFragment: {
    display: "flex",
    paddingTop: "16px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  filterSelection: {
    display: "flex",
  },
  button: {
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    boxShadow:
      "inset 0 0 15px rgba(66, 66, 66, 0), inset 0 0 20px rgba(255, 255, 255, 0), 7px 7px 15px rgba(58, 58, 58, 0.15), -7px -7px 20px white, inset 0px 0px 4px rgba(255, 255, 255, 0.2)",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
    fontFamily: "archia",
    fontWeight: 600,
    textTransform: "none",
  },
  dcDropdown: {
    marginRight: "20px",
  },
}));

interface LayoutHeaderProps {}

const LayoutHeader: React.FC<LayoutHeaderProps> = () => {
  const [currentRoutePaths, setCurrentRoutePaths] = useState<string[]>([]);

  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    let currentRoutes = [];
    currentRoutes =
      location.pathname !== "/" ? location.pathname.split("/") : [];
    if (currentRoutes.length > 0) {
      currentRoutes.shift();
      setCurrentRoutePaths(currentRoutes);
    }
  }, [location]);

  return (
    <div className={classes.headerFragment}>
      <Breadcrumb currentRoutePaths={currentRoutePaths} />
    </div>
  );
};

export default LayoutHeader;
