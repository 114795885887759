import {
  Button,
  Select,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import clsx from "clsx";
import "../../assets/style/Filter.css";

function Filter({ filters, setQueryString }) {
  const [checked, setChecked] = useState(false);

  let filterObj = {};
  for (let filter of filters) {
    if (filter.filterType == "textField") {
      filterObj[filter.name] = "";
    } else {
      filterObj[filter.name] = { label: "", id: "" };
    }
  }

  const [fields, setFields] = useState(filterObj);

  function applyFilters(obj) {
    let url = ``;
    for (let filter of filters) {
      if (filter.filterType == "textField") {
        if (obj[filter.name]) {
          url += `&${filter.param}=${obj[filter.name]}`;
        }
      } else {
        if (obj[filter.name]?.id) {
          url += `&${filter.param}=${obj[filter.name].id}`;
        }
      }
    }
    setQueryString(url);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Fragment>
      <FormGroup className="switch">
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange} />}
          label="Filters"
        />
      </FormGroup>
      {Boolean(checked) && (
        <div className="filter-bar">
          <div className="filters">
            {filters.map((filter) => {
              let filterElement;
              switch (filter.filterType) {
                case "textField":
                  // eslint-disable-next-line no-lone-blocks
                  {
                    filterElement = (
                      <TextField
                        style={{ width: "200px" }}
                        label={filter.name}
                        defaultValue={filter.defaulValue}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            ...{ [filter.name]: e.target.value },
                          });
                        }}
                        value={fields[filter.name]}
                        variant="outlined"
                      />
                    );
                  }
                  break;
                case "dropDown": {
                  filterElement = Boolean(filter.options) ? (
                    <Autocomplete
                      style={{ width: "200px" }}
                      // disableClearable
                      options={filter.options}
                      // sx={{ width: 300 }}
                      getOptionLabel={(item) => {
                        return item.label;
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label={filter.name}
                            variant="outlined"
                          />
                        );
                      }}
                      onChange={(e, value, reason) => {
                        if (value) {
                          setFields({ ...fields, ...{ [filter.name]: value } });
                        }
                      }}
                      onInputChange={(event, value, reason) => {
                        if (reason == "clear") {
                          setFields({ ...fields, ...{ [filter.name]: "" } });
                        }
                      }}
                      value={fields[filter.name]}
                    />
                  ) : (
                    <></>
                  );
                }
              }
              return <div className="filterElement">{filterElement}</div>;
            })}
          </div>
          <Button
            style={{ margin: 20 }}
            className={clsx("activeButton", "addButton", "activeButtonHover")}
            onClick={() => {
              applyFilters(fields);
            }}
          >
            apply
          </Button>
          <Button
            style={{ margin: 20 }}
            className={clsx(
              "passiveButton",
              "cancelButton",
              "passiveButtonHover"
            )}
            onClick={() => {
              setFields(filterObj);
              setQueryString("");
            }}
          >
            Clear
          </Button>
        </div>
      )}
    </Fragment>
  );
}

export default React.memo(Filter);
