import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Phone as PhoneIcon } from "react-feather";

interface MentorshipProps {}

const Mentorship: React.FC<MentorshipProps> = ({}) => {
  return (
    <div>
      <Link to="/adduser">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "180px" }}
        >
          <PhoneIcon />
          Schedule Call
        </div>
      </Link>
    </div>
  );
};

export default Mentorship;
