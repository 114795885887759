import React, { useEffect, useState } from "react";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR from "swr";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import logo from "../../assets/Images/favicon.ico";
import Cookie from "js-cookie";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const Login = () => {
  const [loginData, setLoginData] = useState();
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const { data, error } = useSWR(
    [`/auth/login`, `post`, loginData],
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    Cookie.set("accessToken", data.result?.accessToken, {
      secure: true,
      expires: 7,
    });
    Cookie.set("userId", data.result?.userId, {
      secure: true,
      expires: 7,
    });
    Cookie.set("roles", data.result?.roles, {
      secure: true,
      expires: 7,
    });
    if (data.result?.dashboards) {
      Cookie.set("dashboards", JSON.stringify(data.result?.dashboards), {
        secure: true,
        expires: 7,
      });
    }
    setOpenSuccess(true);
    window.location.replace("/");
  }, [data]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <div
      style={{
        height: "100%",
        background: "linear-gradient(to top, #2c3e50, #fd746c)",
      }}
    >
      <Helmet>
        <title>BoardPrepp Admin Login</title>
      </Helmet>
      <Box
        style={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={(values, actions) => {
              const payload = {
                email: values.email,
                password: values.password,
              };
              setLoginData(payload);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  backgroundColor: "white",
                  padding: "30px",
                  borderRadius: "15px",
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <div className="header" style={{ marginBottom: "30px" }}>
                    <img
                      className="sunyaLogo"
                      src={logo}
                      alt="sunyaLogo"
                      style={{ marginRight: "10px" }}
                    />
                    <Typography color="textPrimary" variant="h3">
                      BoardPrepp Admin Dashboard
                    </Typography>
                  </div>
                  <Typography color="textPrimary" variant="h4">
                    Sign in:
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }} style={{ marginTop: "20px" }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Logged in successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          ERROR: {error?.info}
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Login;
