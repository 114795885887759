import React, { useEffect, useState } from "react";
import Table from "../../components/Commons/Table";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import LinearLoading from "../../components/Commons/LinearLoading";
import TimeField from "../../components/Commons/TimeField";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ShoppingCart } from "react-feather";
import Filter from "../../components/Commons/Filter";
import { Autocomplete } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import {
  TextField,
} from "@material-ui/core";
interface CustomerSubcriptionsProps {}

const CustomerSubcriptions: React.FC<CustomerSubcriptionsProps> = ({}) => {
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [queryString, setQueryString] = useState<string>(``);
  const [mentor, setMentor] = useState<any>({});
  const [snackBar, setSnackBar] = useState({ open: false, message: "" });
  const [tableData, setTableData] = useState(null);


  const { data } = useSWR(
    `/userSubscription/?limit=${limit}&page=${page}${queryString}`,
    fetcher,
    staticOptions
  );

  const { data: postData, error: postError } = useSWR(
    [`/userSubscription/`+mentor.userSubscriptionId, `put`, mentor],
    fetcher,
    staticOptions
  );

  const { data: mentorsList,error: mentorListError } = useSWR(
    `/users?role=MENTOR`,
    fetcher,
    staticOptions
  );

  // const { data: emails } = useSWR(`/userSubscription/`, fetcher, staticOptions);

  const { data: subscriptions } = useSWR(
    `/subscriptionType`,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(postData) || Boolean(postError))
      return;
    setSnackBar({
      open: true,
      message: "Mentor assigned successfully",
    });
    mutate(`/userSubscription/?limit=${limit}&page=${page}${queryString}`);
  }, [postData]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error) || !Boolean(mentorsList) || Boolean(mentorListError)) return;
    const CompData = JSON.parse(
      JSON.stringify(data?.result?.docs)
    );
    CompData?.forEach((item) => {
      item.startDate = timeField(item.startDate);
      if(item.isMentorshipEnabled){
        if (item.mentorUid) {
          item.mentorUid = assignMentor(item._id,item.mentorUid);
        } else {
          item.mentorUid = assignMentor(item._id);
        }
      }
    });
    setTableData(CompData);
  }, [data,mentorsList]);

  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  function assignMentor(userSubscriptionId: string, mentorUid?: string) {
    let defaultName = "";
    if (mentorUid) {
      defaultName = mentorsList?.result?.filter(
        (item) => item.uid == mentorUid
      )[0]?.name;
    }
    return (
      Boolean(data?.result?.docs) &&
      Boolean(mentorsList?.result) && 
        <>
          <Autocomplete
            style={{ width: 150 }}
            disableClearable
            options={mentorsList?.result}
            getOptionLabel={(item) => item.name}
            renderInput={(params) => {
              return <TextField {...params} label="Mentors" />;
            }}
            defaultValue={{ name: defaultName }}
            onChange={(e, value: any, reason) => {
              if (value) {
                setMentor({
                  userSubscriptionId,
                  mentorUid: value.uid,
                });
              }
            }}
          />
        </>
    );
  }

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ open: false, message: "" });
  };

  const filters = [
    {
      name: "Email",
      filterType: "textField",
      options: [],
      defaultValue: "",
      param: "email",
    },
    {
      name: "Subscription Name",
      filterType: "dropDown",
      options: subscriptions?.result?.map((item) => {
        return { label: item.name, id: item._id };
      }),
      defaultValue: { label: "", id: "" },
      param: "subscriptionTypeId",
    },
  ];
  return (
    <div>
      <Link to="/customer-subscriptions/addCustomerSubscription">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "240px" }}
        >
          <ShoppingCart size={"20px"} />
          Add Customer Subscription
        </div>
      </Link>
      <Filter
        filters={filters}
        setQueryString={(query) => {
          setQueryString(query);
          setPage(1);
        }}
      />
      {!!!data?.result ? (
        <LinearLoading />
      ) : (
        <>
          <Table
            data={tableData}
            dataOrder={[
              "email",
              "subscriptionName",
              "subscriptionTypeId",
              'mentorUid',
              "amount",
              "startDate",
              "createdBy",
            ]}
            uniqueId={"_id"}
            enableDelete={true}
            apiEndpoint={"userSubscription"}
            setLimit={setLimit}
            setPage={setPage}
            count={data?.result?.totalDocs}
            currentPage={data?.result?.page}
            pagination={true}
          />
          
        </>
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={1000}
        onClose={handleMessageClose}
      >
        <Alert onClose={handleMessageClose} severity="success">
          {snackBar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default CustomerSubcriptions;
