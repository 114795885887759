import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher, getQueryParam } from "../../utils/Functions";
import { staticOptions, WEB_URL } from "../../utils/Constants";
import { Edit3 } from "react-feather";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

interface BlogsViewProps {}

const BlogsView: React.FC<BlogsViewProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<any>("");

  const { data, error } = useSWR(
    `/blogs/?limit=${limit}&page=${page}`,
    fetcher,
    staticOptions
  );
  useEffect(() => {
    let url = "";
    for (let filter in filters) {
      url += `&${filter}=${filters[filter]}`;
    }
  }, [filters]);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    // if (data?.result?.length > 0) {
    //   for (let i = 0; i < data?.result?.length; i++) {
    //     data.result[i] = {
    //       ...data.result[i],
    //       blogLink: getLinkIcon(data?.result[i].id),
    //     };
    //   }
    // }
    // getQueryParam('isEnable')

    data?.result?.docs?.forEach((item) => {
      item.isVisible = item.isVisible.toString();
      item._id = getLinkIcon(item.id);
    });
  }, [data]);

  function getLinkIcon(url: string) {
    return (
      <Tooltip title="Open File" arrow>
        <IconButton
          aria-label="open"
          onClick={() => window.open(`${WEB_URL}blogs/${url}`, "_blank")}
        >
          <LaunchIcon color="action" />
        </IconButton>
      </Tooltip>
    );
  }

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <React.Fragment>
      <Link to="/blogs/addBlog">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
        >
          <Edit3 size={"20px"} />
          Add Blog
        </div>
      </Link>
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result?.docs}
          uniqueId={"id"}
          enableEdit={true}
          enableDelete={true}
          navTo={"blogs/editBlog"}
          apiEndpoint={"blogs"}
          dataOrder={["name", "_id", "isVisible"]}
          setLimit={setLimit}
          setPage={setPage}
          count={data?.result?.totalDocs}
          currentPage={data?.result?.page}
          pagination={true}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Blogs.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default BlogsView;
