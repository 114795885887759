import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import { FilePlus } from "react-feather";
import Table from "../../components/Commons/Table";
import LinearLoading from "../../components/Commons/LinearLoading";
import TimeField from "../../components/Commons/TimeField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LaunchIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

interface EvaluatorsInfoProps {}

const EvaluatorsInfo: React.FC<EvaluatorsInfoProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const { data, error } = useSWR(
    `mainsTS/evaluatorsMargin/`,
    fetcher,
    staticOptions
  );
  const mainsTSEvaluatorsList = data?.result.evaluatorsList;

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  // useEffect(() => {
  //   if (!Boolean(data) || Boolean(data?.error)) return;
  //   data?.result?.userList?.forEach((item) => {
  //     // item.topicwiseDownload =
  //     //   Boolean(item.topicwiseDownload) && getLinkIcon(item.topicwiseDownload);
  //     // item.sourcewiseDownload =
  //     //   Boolean(item.sourcewiseDownload) &&
  //     //   getLinkIcon(item.sourcewiseDownload);
  //     item.createdAt = timeField(item.createdAt);
  //   });
  // }, [data]);

  // function timeField(date: string) {
  //   return <TimeField date={date} showTime={true} />;
  // }

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  // function getLinkIcon(url: string) {
  //   return (
  //     <Tooltip title="Open File" arrow>
  //       <IconButton aria-label="open">
  //         <LaunchIcon
  //           color="action"
  //           onClick={() => window.open(`${AWS_URL}${url}`, "_blank")}
  //         />
  //       </IconButton>
  //     </Tooltip>
  //   );
  // }

  return (
    <div>
      {!!!mainsTSEvaluatorsList ? (
        <LinearLoading />
      ) : (
        <Table
          data={mainsTSEvaluatorsList}
          uniqueId={"_id"}
          navTo={"mainsTestSeries/editTestSeries"}
          apiEndpoint={"mainsTS"}
          dataOrder={["name", "QP", "EP", "TQA", "TQE", "TEA", "TEE"]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Notes.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default EvaluatorsInfo;
