import React, { useEffect, useState, Fragment, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  Trash2 as DeleteIcon,
  Edit as EditIcon,
  ChevronRight as Next,
  ChevronLeft as Back,
} from "react-feather";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import useSWR, { mutate } from "swr";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import _ from "lodash";
import Filter from "./Filter";

interface TableProps {
  data: Array<{}>;
  dataOrder: Array<string>;
  pagination?: boolean;
  tableHeader?: string;
  uniqueId?: string;
  enableDelete?: boolean;
  enableEdit?: boolean;
  navTo?: string;
  apiEndpoint?: string;
  mutateKey?: string;
  setPage?: any;
  setLimit?: any;
  count?: number;
  currentPage?: number;
  filter?: boolean;
  search?: boolean;
}

const Table: React.FC<TableProps> = ({
  data,
  dataOrder,
  pagination = false,
  tableHeader,
  uniqueId,
  enableDelete = false,
  enableEdit = false,
  navTo,
  apiEndpoint,
  mutateKey,
  setLimit,
  setPage,
  count,
  currentPage,
  filter = false,
  search = false,
}) => {
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [deleteConsent, setDeleteConsent] = useState<boolean>(false);
  const options = {
    filter: filter,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    viewColumns: false,
    pagination: !pagination,
    rowsPerPage: 50,
    download: false,
    count: count,
    page: 0,
    search: search,
    customFooter: !pagination
      ? null
      : (
          count,
          page,
          rowsPerPage,
          changeRowsPerPage,
          changePage,
          textLabels
        ) => {
          return (
            <CustomFooter
              count={count}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              // changeRowsPerPage={changeRowsPerPage}
              setDataPage={setPage}
              // textLabels={textLabels}
            />
          );
        },
  };

  function CustomFooter({ count, page, rowsPerPage, setDataPage }) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "50px",
          alignItems: "center",
          justifyContent: "end",
          paddingRight: "30px",
        }}
      >
        <p style={{ marginRight: "30px" }}>
          {rowsPerPage * (page - 1) + 1}-
          {count > page * rowsPerPage ? page * rowsPerPage : count} of {count}
        </p>
        <Back
          onClick={() => {
            if (page > 1) setDataPage(page - 1);
            else return;
          }}
          style={{ marginRight: "20px", cursor: "pointer" }}
        />

        <Next
          onClick={() => {
            if (page < Math.ceil(count / rowsPerPage)) setDataPage(page + 1);
            else return;
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  }

  const { data: deleteData, error } = useSWR(
    deleteConsent ? [`${apiEndpoint}/${selectedRow}`, "delete"] : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setDeleteConsent(false);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(deleteData) || Boolean(deleteData?.error)) return;
    setDeleteConsent(false);
    mutate(`/${Boolean(mutateKey) ? mutateKey : apiEndpoint}`);
    setOpenSuccess(true);
  }, [deleteData]);

  useEffect(() => {
    if (data?.length) {
      let columnsKeys = dataOrder;
      let columnsList = columnsKeys.map((item) => {
        return {
          name: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          // options: { filter: false },
        };
      });

      //If data ordering is passed in the props, filter the required keys and sort in the given order
      if (dataOrder?.length) {
        columnsList = _.filter(columnsList, (item: any) => {
          return dataOrder.indexOf(item?.name) >= 0;
        });
        columnsList = _.orderBy(columnsList, (item: any) =>
          dataOrder.indexOf(item?.name)
        );
      }

      Boolean(enableEdit) || Boolean(enableDelete)
        ? setColumns([...columnsList, ...actions])
        : setColumns(columnsList);
    }
  }, [data]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            fontFamily: "unset",
            fontWeight: 500,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "unset",
            fontWeight: 600,
          },
        },
        MuiTypography: {
          h6: {
            fontFamily: "unset",
            fontWeight: 600,
            fontSize: "1.1rem",
          },
        },
        MuiPaper: {
          elevation4: {
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.08)",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
          },
        },
      },
    });

  const actions = [
    {
      name: uniqueId,
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {enableEdit && (
                <Link to={`/${navTo}/${value}`}>
                  <Tooltip title="Edit">
                    <EditIcon
                      className="editButton"
                      style={{ marginRight: "10px" }}
                      size={20}
                    />
                  </Tooltip>
                </Link>
              )}
              {enableDelete && (
                <Tooltip title="Delete">
                  <DeleteIcon
                    className="deleteButton"
                    size={20}
                    onClick={() => {
                      setOpen(true);
                      setSelectedRow(value);
                    }}
                  />
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setDeleteConsent(true);
    handleClose();
  };

  return (
    <Fragment>
      {Boolean(data?.length) && (
        <>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={tableHeader}
              data={data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </>
      )}

      <Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Delete Notes</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete <b> {selectedRow}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className={clsx(
                "passiveButton",
                "cancelButton",
                "passiveButtonHover"
              )}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              className={clsx("activeButton", "addButton", "activeButtonHover")}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while deleting.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Deleted successfully.
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Table;
