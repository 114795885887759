import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import LinearLoading from "../../components/Commons/LinearLoading";
import Table from "../../components/Commons/Table";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from "clsx";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

interface MembersProps {}

const Members: React.FC<MembersProps> = ({}) => {
  const [openError, setOpenError] = useState(false);
  const { data, error } = useSWR(`/users?role=MEMBER`, fetcher, staticOptions);

  useEffect(() => {
    if (!Boolean(error) || !Boolean(error?.status)) return;
    console.log(error.info);
    setOpenError(true);
  }, [error]);

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result.forEach((item) => {
      item.isPhoneVerified = item.isPhoneVerified.toString();
      item.isAdmin = item.isAdmin.toString();
      item.isEvaluator = item.isEvaluator.toString();
      item.isEmployee = item.isEmployee.toString();
      item.isMentor=item.isMentor?.toString()??'false';
    });
  }, [data]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <div>
      <Link to="/adduser">
        <div
          className={clsx(
            "addMemberButton",
            "buttonEffectShadow",
            "activeButton"
          )}
          style={{ width: "140px" }}
        >
          <PersonAddIcon />
          Add Member
        </div>
      </Link>
      {!!!data ? (
        <LinearLoading />
      ) : (
        <Table
          data={data?.result}
          uniqueId={"uid"}
          enableDelete={true}
          enableEdit={true}
          apiEndpoint={"users"}
          mutateKey={"users?role=MEMBER"}
          navTo={"edituser"}
          dataOrder={[
            "name",
            "uid",
            "email",
            "phone",
            "isAdmin",
            "isEmployee",
            "isEvaluator",
            'isMentor'
          ]}
        />
      )}
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while fetching Customers List.
        </Alert>
      </Snackbar>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default Members;
