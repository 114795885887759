import { getUserRoles, getEmployeeDashboards } from "../utils/Functions";
import {
  Users as UsersIcon,
  UserPlus as UserPlusIcon,
  Briefcase as BriefcaseIcon,
  DollarSign as DollarSignIcon,
  Book as BookIcon,
  Columns as ColumnsIcon,
  FileText as FileTextIcon,
  Package as PackageIcon,
  Layers as LayersIcon,
  Edit2 as PenIcon,
  ThumbsUp as ThumbsUpIcon,
  Upload as UploadIcon,
  HelpCircle as HelpCircleIcon,
  Clipboard as ClipboardIcon,
  CheckSquare as CheckSquareIcon,
  Award as AwardIcon,
  Tv as TvIcon,
} from "react-feather";

interface subItemsFormat {
  id: string;
  href: string;
  icon: any;
  title: string;
}

interface subItemsArray extends Array<subItemsFormat> {}

interface itemFormat {
  id: string;
  title: string;
  subItems: subItemsArray;
}

interface itemsArray extends Array<itemFormat> {}

//Always add a new dashboard to the array below first
export const dashboards = [
  {
    id: "modules",
    title: "Modules",
  },
  {
    id: "subscriptions",
    title: "Subscriptions",
  },
  {
    id: "notes",
    title: "Notes",
  },
  {
    id: "notelibrary",
    title: "Notes Library",
  },
  {
    id: "blogs",
    title: "Blogs",
  },
  {
    id: "adCategory",
    title: "Ad Categories",
  },
  {
    id: "answers",
    title: "Answers",
  },
  {
    id: "mainsTestSeries",
    title: "TestSeries",
  },
  {
    id: "questions",
    title: "Questions",
  },
  {
    id: "evaluators",
    title: "Evaluators",
  },
  {
    id: "manual-upload",
    title: "Manual Upload",
  },
  {
    id: "customers",
    title: "Customers",
  },
  {
    id: "customer-subscriptions",
    title: "Customer Subscriptions",
  },
  {
    id: "payments",
    title: "Payments",
  },
  {
    id: "members",
    title: "Members",
  },
  {
    id: "mentorship",
    title: "Mentorship",
  },
  {
    id: "mentors",
    title: "Mentors",
  },
];

const getDashboards = () => {
  if (getUserRoles?.isAdmin) return adminDashboards;
  else if (getUserRoles?.isEvaluator) return evaluatorDashboards;
  else if (getUserRoles?.isEmployee) return employeeDashboards();
};

const adminDashboards: itemsArray = [
  {
    id: "products",
    title: "Products & Services",
    subItems: [
      {
        id: "modules",
        href: "/modules",
        icon: ColumnsIcon,
        title: "Modules",
      },
      {
        id: "subscriptions",
        href: "/subscriptions",
        icon: PackageIcon,
        title: "Subscriptions",
      },
      {
        id: "notes",
        href: "/notes",
        icon: FileTextIcon,
        title: "Notes",
      },
      {
        id: "notelibrary",
        href: "/notelibrary",
        icon: BookIcon,
        title: "Notes Library",
      },
      {
        id: "blogs",
        href: "/blogs",
        icon: PenIcon,
        title: "Blogs",
      },
      {
        id: "adCategory",
        href: "/adCategory",
        icon: ThumbsUpIcon,
        title: "Ad Categories",
      },
      {
        id: "mentorship",
        href: "/mentorship",
        icon: AwardIcon,
        title: "Mentorship",
      },
    ],
  },
  {
    id: "mains",
    title: "Mains TestSeries",
    subItems: [
      {
        id: "answers",
        href: "/answers",
        icon: LayersIcon,
        title: "Answers",
      },
      {
        id: "mainsTestSeries",
        href: "/mainsTestSeries",
        icon: ClipboardIcon,
        title: "TestSeries",
      },
      {
        id: "questions",
        href: "/questions",
        icon: HelpCircleIcon,
        title: "Questions",
      },
      {
        id: "evaluators",
        href: "/evaluators",
        icon: CheckSquareIcon,
        title: "Evaluators",
      },
      {
        id: "manual-upload",
        href: "/manual-upload",
        icon: UploadIcon,
        title: "Manual Upload",
      },
    ],
  },
  {
    id: "management",
    title: "Management",
    subItems: [
      {
        id: "customer-subscriptions",
        href: "/customer-subscriptions",
        icon: UserPlusIcon,
        title: "Customer Subscriptions",
      },
      {
        id: "payments",
        href: "/payments",
        icon: DollarSignIcon,
        title: "Payments",
      },
    ],
  },
  {
    id: "administration",
    title: "Administration",
    subItems: [
      {
        id: "customers",
        href: "/customers",
        icon: UsersIcon,
        title: "Customers",
      },
      {
        id: "members",
        href: "/members",
        icon: BriefcaseIcon,
        title: "Members",
      },
      {
        id: "mentors",
        href: "/mentors",
        icon: TvIcon,
        title: "Mentors",
      },
    ],
  },
].filter((value) => Object.keys(value).length !== 0);

const evaluatorDashboards: itemsArray = [
  {
    id: "mains",
    title: "Mains TestSeries",
    subItems: [
      {
        id: "answers/evaluator",
        href: "/answers/evaluator",
        icon: LayersIcon,
        title: "Answers",
      },
    ],
  },
];

const employeeDashboards = () => {
  let menuItems: itemsArray = [];
  adminDashboards.forEach((item) => {
    const tempItem = { ...item };
    tempItem.subItems = [];
    item.subItems?.forEach((sub) => {
      if (getEmployeeDashboards.indexOf(sub.id) > -1)
        tempItem.subItems.push(sub);
    });
    if (tempItem.subItems?.length) menuItems.push(tempItem);
  });
  return menuItems;
};

//Add a new dashboard to one or multiple roles below
export const menuItems: itemsArray | undefined = getDashboards();
