import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import useSWR from "swr";
import {
  fetcher,
  customFetcher,
  fileNameShortner,
} from "../../utils/Functions";
import { staticOptions, AWS_URL } from "../../utils/Constants";
import LaunchIcon from "@material-ui/icons/Launch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { green } from "@material-ui/core/colors";

interface FileUploaderPresignedProps {
  setFileUrl?: (url: string) => void;
}

const FileUploaderPresigned: React.FC<FileUploaderPresignedProps> = ({
  setFileUrl,
}) => {
  const [formData, setFormData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [fileExtension, setFileExtension] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [uploadStatus, setUploadStatus] = useState<string>("NOT_UPLOADING");
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  //STEP 1: Get Presigned URL
  const { data: presignedPostData, error: presignedPostError } = useSWR(
    Boolean(fileExtension)
      ? `/upload/presigned?fileExtension=${fileExtension}&fileName=${fileName}`
      : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(presignedPostData) || Boolean(presignedPostData?.error))
      return;
    const postData = presignedPostData?.result;
    let form = new FormData();
    Object.keys(postData.fields).forEach((key) => {
      form.append(key, postData.fields[key]);
    });
    form.append("file", fileData);
    setFormData(form);
  }, [presignedPostData]);

  useEffect(() => {
    if (!Boolean(presignedPostError) || !Boolean(presignedPostError?.status))
      return;
    console.log(presignedPostError.info);
    setOpenError(true);
    setUploadStatus("NOT_UPLOADING");
  }, [presignedPostError]);

  //STEP 2: Upload the file to S3 using the presigned URL.
  const { data: uploadData, error: uploadError } = useSWR(
    Boolean(formData)
      ? [`${presignedPostData?.result?.url}`, `post`, formData]
      : null,
    customFetcher,
    staticOptions
  );

  //STEP 3: On Upload Success, set the fileURL Prop
  useEffect(() => {
    if (!Boolean(uploadData)) return;
    setFileUrl(presignedPostData?.result?.fields?.key?.replace(/(.*)\//g, ""));
    setUploadStatus("UPLOADED");
    setFormData({});
    setOpenSuccess(true);
    setFileUrl(presignedPostData?.result?.fields?.key?.replace(/(.*)\//g, ""));
  }, [uploadData]);

  function fileUpload(e) {
    if (!e.target?.files[0]) return;
    setUploadStatus("UPLOADING");
    let file = e.target.files[0];
    setFileData(file);
    setFileName(fileNameShortner(file?.name));
    setFileExtension(file?.type);
  }

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };
  return (
    <>
      {uploadStatus === "UPLOADING" && <CircularProgress size={30} />}
      {uploadStatus === "UPLOADED" && (
        <Tooltip title="Open uploaded File" arrow>
          <IconButton aria-label="open">
            <LaunchIcon
              color="primary"
              onClick={() =>
                window.open(
                  `${AWS_URL}${presignedPostData?.result?.fields?.key?.replace(
                    /(.*)\//g,
                    ""
                  )}`,
                  "_blank"
                )
              }
              style={{ color: green[700] }}
            />
          </IconButton>
        </Tooltip>
      )}

      <Button
        variant="contained"
        component="label"
        size="small"
        startIcon={<CloudUploadIcon color="action" />}
      >
        Upload File
        <input
          id="inputOptions"
          type="file"
          name="sourcewise"
          accept="application/pdf,image/*"
          style={{ display: "none" }}
          onChange={fileUpload}
        />
      </Button>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          File uploaded successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Error while uploading file!
        </Alert>
      </Snackbar>
    </>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default FileUploaderPresigned;
