import React from "react";
import image from "../../assets/Images/no-access.svg";

interface NoAccessProps {}

const NoAccess: React.FC<NoAccessProps> = ({}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img
        src={image}
        style={{ width: "100%", height: "400px", marginTop: "30px" }}
        alt="underconstruction"
      />
      <h2 style={{ color: "#2f4f4f", marginTop: "40px" }}>
        Not sure how you ended up here.{" "}
        <span
          style={{
            color: "#e23028",
            background: "rgba(226,48,40,.06274509803921569)",
          }}
        >
          Access denied.
        </span>
      </h2>
    </div>
  );
};

export default NoAccess;
